import {
  APIFetchTrafficPrediction,
  APIFetchKPIPrediction
} from '@/api/prediction';
import {
  APIFetchTrafficDataByStore
} from '@/api/traffic';
import {
  getDateRangeForPrediction,
  convertIdToDatePredictionData,
  generateNext7DaysPrediction
} from '@/util/utilFidaFunctions';

import {
  getSumOfKpi
} from '@/api/fida';
const initialState = {
  traffic: { averagePrediction: 0 },
  expectations: {},
  kpiPrediction: {
    previousData: [],
    predictionData: []
  }
}; // initialState

export default {
  state: initialState, // state
  mutations: {
    setKpiPrediction(state, { kpiPrediction }) {
      state.kpiPrediction = {
        ...state.kpiPrediction,
        previousData: [...kpiPrediction.last7DaysData],
        predictionData: [...kpiPrediction.predictionData],
        predictionDataC: [...kpiPrediction.predictionDataC]
      };
    },
    setTraffic(state, { traffic }) {
      traffic.last7DaysData = traffic.last7DaysData.slice(0, 7);
      state.traffic = traffic;
    }, // setTraffic
    setExpectations(state, { expectations }) {
      state.expectations = expectations;
    } // setExpectations
  }, // mutations
  actions: {
    fetchKpiPrediction: async (
      { commit },
      { metricKey, storeCodes, daysOfWeek }
    ) => {

     const { startDate, endDate, compareStartDate, compareEndDate } = getDateRangeForPrediction();
     const res = await APIFetchTrafficDataByStore(
        {
          startDate,
          endDate,
          compareEndDate,
          compareStartDate,
          daysOfWeek,
          storeCodes,
          frequency: 'daily',
        }
      );
      res.kpis = getSumOfKpi(res.kpis);
      res.kpisCompare = getSumOfKpi(res.kpisCompare);
      //
      const last7DaysData = convertIdToDatePredictionData(res.kpis);
      const last7DaysDataC = convertIdToDatePredictionData(res.kpisCompare);
      const next7DaysPrediction = generateNext7DaysPrediction(last7DaysData);
      const next7DaysPredictionC = generateNext7DaysPrediction(last7DaysDataC);

      const data = {
        predictionData: next7DaysPrediction,
        predictionDataC: last7DaysDataC,
        last7DaysData: last7DaysData,
        averagePrediction: "5000",
        metricKey: "Traffic"
      };

      // const predictionResponse = await APIFetchKPIPrediction(metricKey, {
      //   storeCodes,
      //   daysOfWeek
      // });

      commit('setKpiPrediction', { kpiPrediction: data });


      // commit('setKpiPrediction', { kpiPrediction: predictionResponse.data });
    },
    // fetchKpiPrediction: async (
    //   { commit },
    //   { metricKey, storeCodes, daysOfWeek }
    // ) => {
    //   const predictionResponse = await APIFetchKPIPrediction(metricKey, {
    //     storeCodes,
    //     daysOfWeek
    //   });
    //   commit('setKpiPrediction', { kpiPrediction: predictionResponse.data });
    // },
    async fetchTrafficPrediction({ commit }, { storeCodes, daysOfWeek }) {
      const res = await APIFetchTrafficPrediction({
        daysOfWeek,
        storeCodes
      });
      if (res.data) {
        commit('setTraffic', { traffic: res.data.predictionData });
        commit('setExpectations', { expectations: res.data.wte });
      }
    } // fetchTrafficPrediction
  } // actions
}; // export default