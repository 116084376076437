var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('Box',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"target-header"},[_c('div',{staticClass:"achievement"},[_c('h2',[_vm._v(_vm._s(_vm.$store.state.user.translate.dashboard))])]),_c('div',{staticClass:"add-saletarget-head"},[(_vm.importPermission && !_vm.targets.isPreviousData)?_c('span',[_c('q-card',{staticClass:"col-target-head",staticStyle:{"margin-right":"8px"}},[_c('div',{staticClass:"target-data",staticStyle:{"margin-bottom":"0px"}},[_c('p',{staticClass:"text-custom-gray q-mb-none",on:{"click":_vm.addModel}},[_c('i',{staticClass:"fas fa-plus"}),_vm._v("  "+_vm._s(_vm.$store.state.user.translate.sales_targets)+" ")])])])],1):_vm._e(),_c('q-card',{staticClass:"col-target-head"},[_c('div',{staticClass:"target-data",staticStyle:{"margin-bottom":"0px"}},[_c('p',{staticClass:"text-custom-gray q-mb-none",on:{"click":function($event){return _vm.$router.push({ name: 'trafficcalculator' })}}},[_c('i',{staticClass:"fas fa-calculator"}),_vm._v("  "+_vm._s(_vm.$store.state.user.translate.title_traffic_calculator)+" ")])])])],1)])]},proxy:true},{key:"body",fn:function(){return [_c('div',{staticClass:"target-body"},[_c('div',{staticClass:"target-box-col"},[_c('q-card',{staticClass:"col-target"},[_c('div',{staticClass:"target-data"},[_c('p',{staticClass:"text-custom-gray q-mb-none"},[_vm._v(" "+_vm._s(_vm.$store.state.user.translate.target_achievement_rate_daily)+" ")]),_c('div',{staticClass:"text-custom-gray text-subtitle1"},[(
                _vm.targets.isPreviousData && _vm.targets.monthlyTarget.target > 0
              )?_c('span',[_vm._v(_vm._s(_vm.$store.state.user.translate.sales_target_predicted_sales))]):_c('span',[_vm._v(_vm._s(_vm.$store.state.user.translate.sales_target))]),_vm._v(" "+_vm._s(_vm.targets && _vm.targets.dailyTarget && _vm.targets.dailyTarget.targetFormatted)+" ")])]),_c('CircularProgress',{attrs:{"value":_vm.getTargetPercent(
              _vm.targets && _vm.targets.dailyTarget
                ? _vm.targets.dailyTarget.achieved
                : 0,
              _vm.targets && _vm.targets.dailyTarget ? _vm.targets.dailyTarget.target : 0
            ),"value2":_vm.getTargetPercent(
              _vm.targets?.dailyTarget?.expectedTar || 0,
              _vm.targets?.dailyTarget?.target || 0
            ),"expected":_vm.targets?.dailyTarget?.expectedTargetFormatted,"achieved":_vm.targets &&
              _vm.targets.dailyTarget &&
              _vm.targets.dailyTarget.achievedFormatted,"is-target":true,"tar-type":1}}),_c('div',{staticClass:"target-bottom"},[_c('button',{staticClass:"kpi-tree-btn",on:{"click":function($event){return _vm.openKpiTree('daily')}}},[_c('img',{attrs:{"src":require("../../../assets/list-tree.png")}}),_vm._v(" "+_vm._s(_vm.$store.state.user.translate.kpitree_button)+" ")]),(_vm.suggested)?_c('button',{staticClass:"suggested-action",on:{"click":_vm.sugestedModal}},[_c('img',{attrs:{"src":require("../../../assets/sparkles.png")}}),_vm._v(" "+_vm._s(_vm.$store.state.user.translate .kpi_button_popuptitle_suggestedactions)+" ")]):_vm._e()])],1)],1),_c('div',{staticClass:"target-box-col"},[_c('q-card',{staticClass:"col-target"},[_c('div',{staticClass:"target-data"},[_c('p',{staticClass:"text-custom-gray q-mb-none"},[_vm._v(" "+_vm._s(_vm.$store.state.user.translate.target_achievement_rate_weekly)+" ")]),_c('div',{staticClass:"text-custom-gray text-subtitle1"},[_c('div',{staticClass:"text-custom-gray text-subtitle1"},[(
                  _vm.targets.isPreviousData && _vm.targets.monthlyTarget.target > 0
                )?_c('span',[_vm._v(_vm._s(_vm.$store.state.user.translate.sales_target_predicted_sales))]):_c('span',[_vm._v(_vm._s(_vm.$store.state.user.translate.sales_target))]),_vm._v(" "+_vm._s(_vm.targets && _vm.targets.weeklyTarget && _vm.targets.weeklyTarget.targetFormatted ? _vm.targets.weeklyTarget.targetFormatted : '0')+" ")])])]),_c('CircularProgress',{attrs:{"value":_vm.getTargetPercent(
              _vm.targets && _vm.targets.weeklyTarget
                ? _vm.targets.weeklyTarget.achieved
                : 0,
              _vm.targets && _vm.targets.weeklyTarget
                ? _vm.targets.weeklyTarget.target
                : 0
            ),"value2":_vm.getTargetPercent(
              _vm.targets?.weeklyTarget?.expectedTar || 0,
              _vm.targets?.weeklyTarget?.target || 0
            ),"expected":_vm.targets?.weeklyTarget?.expectedTargetFormatted,"achieved":_vm.targets &&
              _vm.targets.weeklyTarget &&
              _vm.targets.weeklyTarget.achievedFormatted,"is-target":true,"tar-type":2}}),_c('div',{staticClass:"target-bottom"},[_c('button',{staticClass:"kpi-tree-btn",on:{"click":function($event){return _vm.openKpiTree('weekly')}}},[_c('img',{attrs:{"src":require("../../../assets/list-tree.png")}}),_vm._v(" "+_vm._s(_vm.$store.state.user.translate.kpitree_button)+" ")])])],1)],1),_c('div',{staticClass:"target-box-col"},[_c('q-card',{staticClass:"col-target"},[_c('div',{staticClass:"target-data"},[_c('p',{staticClass:"text-custom-gray q-mb-none"},[_vm._v(" "+_vm._s(_vm.$store.state.user.translate.target_achievement_rate_monthly)+" ")]),_c('div',{staticClass:"text-custom-gray text-subtitle1"},[_c('div',{staticClass:"text-custom-gray text-subtitle1"},[(
                  _vm.targets.isPreviousData && _vm.targets.monthlyTarget.target > 0
                )?_c('span',[_vm._v(_vm._s(_vm.$store.state.user.translate.sales_target_predicted_sales))]):_c('span',[_vm._v(_vm._s(_vm.$store.state.user.translate.sales_target))]),_vm._v(" "+_vm._s(_vm.targets && _vm.targets.monthlyTarget && _vm.targets.monthlyTarget.targetFormatted)+" ")])])]),_c('CircularProgress',{attrs:{"value":_vm.getTargetPercent(
              _vm.targets && _vm.targets.monthlyTarget
                ? _vm.targets.monthlyTarget.achieved
                : 0,
              _vm.targets && _vm.targets.monthlyTarget
                ? _vm.targets.monthlyTarget.target
                : 0
            ),"value2":_vm.getTargetPercent(
              _vm.targets?.monthlyTarget?.expectedTar || 0,
              _vm.targets?.monthlyTarget?.target || 0
            ),"expected":_vm.targets?.monthlyTarget?.expectedTargetFormatted,"achieved":_vm.targets &&
              _vm.targets.monthlyTarget &&
              _vm.targets.monthlyTarget.achievedFormatted,"is-target":true,"tar-type":3}}),_c('div',{staticClass:"target-bottom"},[_c('button',{staticClass:"kpi-tree-btn",on:{"click":function($event){return _vm.openKpiTree('monthly')}}},[_c('img',{attrs:{"src":require("../../../assets/list-tree.png")}}),_vm._v(" "+_vm._s(_vm.$store.state.user.translate.kpitree_button)+" ")])])],1)],1),_c('LoadingSpinner',{attrs:{"loading":_vm.loadingTarget || _vm.loading}})],1),_c('div',{staticClass:"target-encouragement"},[(
          _vm.importPermission &&
            _vm.targets.isPreviousData &&
            _vm.targets.monthlyTarget.target > 0
        )?_c('div',[_vm._v(" "+_vm._s(_vm.$store.state.user.translate.sales_target_admin_not_added_1)+" "+_vm._s(_vm.$store.state.user.translate.sales_target_admin_not_added_2)+" "),_c('span',{staticClass:"add-saletarget-inline",on:{"click":_vm.addModel}},[_vm._v(_vm._s(_vm.$store.state.user.translate.sales_target_admin_not_added_3)+" ")]),_vm._v(" "+_vm._s(_vm.$store.state.user.translate.sales_target_admin_not_added_4)+" ")]):(
          !_vm.importPermission &&
            _vm.targets.isPreviousData &&
            _vm.targets.monthlyTarget.target > 0
        )?_c('div',[_vm._v(" "+_vm._s(_vm.$store.state.user.translate.sales_target_non_admin_not_added)+" ")]):(
          _vm.importPermission &&
            _vm.targets.isPreviousData &&
            _vm.targets.monthlyTarget.target == 0
        )?_c('div',[_c('span',{staticClass:"add-saletarget-inline",on:{"click":_vm.addModel}},[_vm._v(" "+_vm._s(_vm.$store.state.user.translate .sales_target_admin_no_historical_data_1))]),_vm._v(" "+_vm._s(_vm.$store.state.user.translate.sales_target_admin_no_historical_data_2)+" "),_c('span',{staticClass:"add-saletarget-inline",on:{"click":_vm.addModel}},[_vm._v(" "+_vm._s(_vm.$store.state.user.translate .sales_target_admin_no_historical_data_3))])]):_vm._e(),(
          !_vm.importPermission &&
            _vm.targets.isPreviousData &&
            _vm.targets.monthlyTarget.target == 0
        )?_c('div',[_vm._v(" "+_vm._s(_vm.$store.state.user.translate .sales_target_non_admin_no_historical_data)+" ")]):_vm._e()]),_c('AddSalesTarget',{ref:"addSales"}),_c('KpiTree',{ref:"kpiTree",attrs:{"type":_vm.kpiTreeType}}),_c('SuggestedActions',{ref:"sugestedModal"})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }