
import { mapGetters, mapActions, mapState } from 'vuex';
import { getSignsLeftSide,getSignsRightSide } from '@/util/replaceFidaKpi';

export default {
  props: {
    heatmapmode: {
      type: String,
      required: true,
      default: ''
    },
    setheatmapmode: {
      type: Function,
      required: true,
      default: () => {}
    },
    options: {
      type: Object,
      required: false,
      default: () => {}
    } // options
  },
  data: function() {
    return {
      categories: [],
      added: false,
      maxVal: 0,
      minVal: 0,
      median: 0,
      daysOfWeek: [
        this.$t('monday'),
        this.$t('tuesday'),
        this.$t('wednesday'),
        this.$t('thursday'),
        this.$t('friday'),
        this.$t('saturday'),
        this.$t('sunday')
      ],
      mode: '',
      loading: false,
      formattedValuesList: [],
      dailyValue: '',
      series: [],
      seriesLeft: [],
      seriesBottom: [],
      chartOptionsBottom: {
        legend: {
          show: false
        },
        chart: {
          height: 90,
          type: 'heatmap',
          toolbar: {
            show: false
          }
        },
        dataLabels: {
          enabled: false,
          background: {
            enabled: true,
            foreColor: '#ff3046'
          },
          style: {
            fontSize: '14px',
            colors: ['#000000']
          },
          formatter: val => {
            return val;
          }
        },
        tooltip: {
          custom: ({ series, seriesIndex, dataPointIndex, w }) => {
            return `
            <q-card class="flex column q-pa-md">
            <div class="tool-tip-yaxis">${
              w.globals.seriesNames[seriesIndex]
            }</div>
                     <div class="flex items-center">
                       <div class="day-label-tool-tip text-bold">${w.globals.labels[
                         dataPointIndex
                       ].join(' ')}</div>
                     </div>
                </q-card>
            `;
          }
        },
        responsive: [
          {
            breakpoint: 426,
            options: {
              dataLabels: {
                enabled: false
              }
            }
          }
        ]
      },
      chartOptionsLeft: {
        legend: {
          show: false
        },
        chart: {
          height: 358,
          type: 'heatmap',
          toolbar: {
            show: false
          }
        },
        dataLabels: {
          enabled: false,
          background: {
            enabled: true,
            foreColor: '#ff3046'
          },
          style: {
            fontSize: '14px',
            colors: ['#000000']
          },
          formatter: val => {
            return val.toFixed(0);
          }
        },
        xaxis: {
          type: 'category',
          categories: ['Day Total'],
          labels: {
            show: false
          }
        },
        tooltip: {
          custom: ({ series, seriesIndex, dataPointIndex, w }) => {
            return `
            <q-card class="flex column q-pa-md">
            <div class="tool-tip-yaxis">${
              w.globals.labels[dataPointIndex]
            }</div>
                     <div class="flex items-center">
                       <div class="day-label-tool-tip text-bold">${w.globals.seriesNames[
                         seriesIndex
                       ].join(' ')}</div>
                     </div>
                </q-card>
            `;
          }
        },
        responsive: [
          {
            breakpoint: 426,
            options: {
              dataLabels: {
                enabled: false
              }
            }
          }
        ]
      },
      chartOptions: {
        legend: {
          show: false
        },
        chart: {
          height: 350,
          type: 'heatmap',
          toolbar: {
            show: true,
            tools: {
              download: '<i class="fas fa-download"></i>'
            }
          }
        },

        dataLabels: {
          // enabled: true
          background: {
            enabled: true,
            foreColor: '#ff3046'
          },
          style: {
            fontSize: '14px',
            colors: ['#000000']
          },
          formatter: (val, series) => {
            return this.formattedValuesList[series.seriesIndex][
              series.dataPointIndex
            ];
          }
        },
        xaxis: {
          type: 'category',
          categories: []
        },
        title: {},
        tooltip: {
          custom: ({ series, seriesIndex, dataPointIndex, w }) => {
            return `
            <q-card class="flex column q-pa-md">
            <div class="tool-tip-yaxis">${w.globals.labels[dataPointIndex]}</div>
                     <div class="flex items-center">
                       <div class="day-label-tool-tip">${w.globals.seriesNames[seriesIndex]}:</div>
                     <label class="text-bold">${this.formattedValuesList[seriesIndex][dataPointIndex]}</label></div>
                </q-card>
            `;
          }
        },
        responsive: [
          {
            breakpoint: 426,
            options: {
              dataLabels: {
                enabled: false
              }
            }
          }
        ]
      },
      fida: 'true'
    };
  },

  computed: {
    ...mapGetters('filter', [
      'getStoreCodesList',
      'getDaysOfWeekList',
      'getStartingPeriod',
      'getEndingPeriod',
      'getStartingCompared',
      'getEndingCompared'
    ]), // mapGetters
    ...mapState('traffic', ['powerHourData','trafficList','loadingPower']),

    ...mapGetters('user', ['getLocale']),
    valueRanges() {
      let intMax = parseInt(this.maxVal);
      let intMin = parseInt(this.minVal);
      let dv = intMax - intMin;
      let arr = [
        intMin,
        parseInt(intMin + parseFloat((dv * 0.1)?.toFixed(1))),
        parseInt(intMin + parseFloat((dv * 0.2)?.toFixed(1))),
        parseInt(intMin + parseFloat((dv * 0.3)?.toFixed(1))),
        parseInt(intMin + parseFloat((dv * 0.4)?.toFixed(1))),
        parseInt(intMin + parseFloat((dv * 0.5)?.toFixed(1))),
        parseInt(intMin + parseFloat((dv * 0.6)?.toFixed(1))),
        parseInt(intMin + parseFloat((dv * 0.7)?.toFixed(1))),
        parseInt(intMin + parseFloat((dv * 0.8)?.toFixed(1))),
        parseInt(intMin + parseFloat((dv * 0.9)?.toFixed(1))),
        intMax
      ];
      return arr;
    },
  }, // computed
  watch: {
   async trafficList() {
      await this.refreshComponent();

  },
    heatmapmode: async function(newValue) {
      this.mode = newValue;
        try {
          const trafficInOut = this.trafficList.trafficList;
          await this.fetchStores();
          await this.fetchPowerHourFidaV2({trafficInOut:trafficInOut,mode: newValue,daysOfWeek:this.getDaysOfWeekList,storeCodes:this.getStoreCodesList,startDate: this.getStartingPeriod, endDate: this.getEndingPeriod });

          this.populateHeatMap();
        } catch (error) {
          this.$q.notify({
            type: 'negative',
            position: 'top',
            message: error
          });
        }
    },
    mode: async function(val) {
      this.setheatmapmode(val);
    },
    powerHourData() {
      this.populateHeatMap();
    }
  },
  created: async function() {

    this.chartOptions.chart.toolbar = {
      ...this.chartOptions.chart.toolbar,
      export: {
        svg: {
          filename: `${this.$t('powerhour')} ${this.$t(
            'KpiAnalysis.selected'
          )}꞉ ${this.getStartingPeriod}˷${this.getEndingPeriod}  ${this.$t(
            'KpiAnalysis.compared'
          )}꞉ ${this.getStartingCompared}˷${this.getEndingCompared}`
        },
        png: {
          filename: `${this.$t('powerhour')} ${this.$t(
            'KpiAnalysis.selected'
          )}꞉ ${this.getStartingPeriod}˷${this.getEndingPeriod}  ${this.$t(
            'KpiAnalysis.compared'
          )}꞉ ${this.getStartingCompared}˷${this.getEndingCompared}`
        },
        csv: {
          filename: `${this.$t('powerhour')} ${this.$t(
            'KpiAnalysis.selected'
          )}꞉ ${this.getStartingPeriod}˷${this.getEndingPeriod}  ${this.$t(
            'KpiAnalysis.compared'
          )}꞉ ${this.getStartingCompared}˷${this.getEndingCompared}`
        }
      }
    };
    this.mode = this.heatmapmode;
  }, //created
  methods: {
    ...mapActions('traffic', ['fetchPowerHourFidaV2']),
    ...mapActions('traffic', ['fetchPowerHourData']),
    ...mapActions('user', ['fetchStores']),
    csvHandler: function () {
      var csvFileData = this.series.map(data => {
        return [this.encodeCSVField(data.name), ...data.data.map(item => this.encodeCSVField(item))];
      });

      var csv = `category,${this.categories.toString()}\n`;

      csvFileData.reverse().forEach(function (row) {
        csv += row.join(',');
        csv += '\n';
      });

      var hiddenElement = document.createElement('a');
      var csvContent = '\uFEFF' + csv; // BOM character for Excel compatibility
      var encodedUri = encodeURI(csvContent);
      hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodedUri;
      hiddenElement.target = '_blank';

      var filename = `${this.$t('powerhour')} ${this.$t('KpiAnalysis.selected')}꞉ ${this.getStartingPeriod}˷${this.getEndingPeriod}  ${this.$t('KpiAnalysis.compared')}꞉ ${this.getStartingCompared}˷${this.getEndingCompared}`;
      hiddenElement.download = filename;
      hiddenElement.click();
      },

  encodeCSVField: function (value) {
  if (/[",\n]/.test(value)) {
    return `"${value.replace(/"/g, '""')}"`;
  }
  return value;
  },

  clickFunction: function(event, chartContext, config) {
    const div = document.createElement('div');
    const language = this.getLocale;
    if(language == 'en')
      div.textContent = 'Download CSV';
    else
      div.textContent = 'CSVダウンロード';

    div.className = 'apexcharts-menu-item exportCSV';
    var ele = document.getElementsByClassName('apexcharts-menu');
    if (!this.added) {
      ele[2].appendChild(div);
      ele[2].childNodes[2].style.display = 'none';
      div.addEventListener('click', this.csvHandler);
      this.added = true;
    }
  },
    populateHeatMap: function() {
      let oK = Object.keys(this.powerHourData);
      let m = 0;
      let mD = null;
      oK.forEach(k => {
        if (this.powerHourData[k].length > m) {
          m = this.powerHourData[k].length;
          mD = this.powerHourData[k];
        }
      });

      oK.forEach(k => {
        if (this.powerHourData[k].length < m) {
          for (let i = this.powerHourData[k].length; i < m; i++) {
            this.powerHourData[k].push({
              ...mD[i],
              ...{ formattedValue: '0 ', value: 0 }
            });
          }
        }
      });
      if (this.powerHourData && Object.keys(this.powerHourData).length) {
        let dataList = [];
        this.formattedValuesList = [];
        this.series = Object.values(this.powerHourData)
          .map((v, key) => {
            dataList = [...dataList, ...v.map(dat => dat.value)];
            return {
              name: this.$t(Object.keys(this.powerHourData)[key].toLowerCase()),
              data: v.map(dat => dat.value),
              formattedValue: v.map(dat => (dat.formattedValue.toLocaleString()))
            };
          })
          .sort(this.daysOfWeekSorter)
          .reverse();
        const leftSeries = Object.values(this.powerHourData)
          .map((v, key) => {
            const newArray = [];
            v.map(dat => {
              newArray.push(dat.value);
            });
            const weekTotal = newArray.reduce((a, b) => a + b, 0);
            return {
              name: this.$t(Object.keys(this.powerHourData)[key].toLowerCase()),
              data: [weekTotal.toFixed(0)],
              formattedValue: [weekTotal.toFixed(0)]
            };
          })
          .sort(this.daysOfWeekSorter)
          .reverse();
        const bottomChartsData = this.series.map(d => {
          return d.data.map(v => {
            return v;
          });
        });
        let hourTotal = [];
        bottomChartsData[0].forEach((v, index) => {
          let sum = 0;
          bottomChartsData.map(m => {
            sum = sum + m[index];
          });
          hourTotal.push(sum.toLocaleString());
        });
        this.seriesBottom = [
          {
            name: 'Hourly Total',
            data: hourTotal
          }
        ];
        this.maxVal = parseInt(Math.max(...dataList))?.toFixed(0);
        this.minVal = parseInt(Math.min(...dataList))?.toFixed(0);
        this.median = (
          (parseInt(this.maxVal) + parseInt(this.minVal)) /
          2
        )?.toFixed(0);
        this.series.map(v => {
          this.formattedValuesList = [
            ...this.formattedValuesList,
            v.formattedValue
          ];
        });
        this.seriesLeft = leftSeries.map(v => {
          const vData = Number(v.data?v.data:0).toLocaleString();
         
          const newLabel = [v.name, vData];
       //   const newLabel = [v.name, v.data];
          return {
            name: newLabel,
            data: v.data,
            formattedValue: v.formattedValue
          };
        });
      }

      if (this.powerHourData && Object.keys(this.powerHourData).length) {
        let categories = Object.keys(this.powerHourData)
          .sort(
            (x, y) => this.daysOfWeek.indexOf(x) - this.daysOfWeek.indexOf(y)
          )
          .map(v => this.powerHourData[v])[0]
          .map(v => v.hour);

        this.categories = categories;

        const plotOption = {
          plotOptions: {
            heatmap: {
              enableShades: false,
              shadesIntensity: 0,
              colorScale: {
                ranges: [
                  {
                    from: this.valueRanges[0],
                    to: this.valueRanges[1],
                    color: '#ebf2fa',
                    name: this.valueRanges[1]
                  },
                  {
                    from: this.valueRanges[1],
                    to: this.valueRanges[2],
                    color: '#d8e5f6',
                    name: this.valueRanges[2]
                  },
                  {
                    from: this.valueRanges[2],
                    to: this.valueRanges[3],
                    color: '#c5d7f1',
                    name: this.valueRanges[3]
                  },
                  {
                    from: this.valueRanges[3],
                    to: this.valueRanges[4],
                    color: '#b2cbed',
                    name: this.valueRanges[4]
                  },
                  {
                    from: this.valueRanges[4],
                    to: this.valueRanges[5],
                    color: '#9ebde8',
                    name: this.valueRanges[5]
                  },
                  {
                    from: this.valueRanges[5],
                    to: this.valueRanges[6],
                    color: '#8bb0e4',
                    name: this.valueRanges[6]
                  },
                  {
                    from: this.valueRanges[6],
                    to: this.valueRanges[7],
                    color: '#78a3df',
                    name: this.valueRanges[7]
                  },
                  {
                    from: this.valueRanges[7],
                    to: this.valueRanges[8],
                    color: '#6596db',
                    name: this.valueRanges[8]
                  },
                  {
                    from: this.valueRanges[8],
                    to: this.valueRanges[9],
                    color: '#5189d6',
                    name: this.valueRanges[9]
                  },
                  {
                    from: this.valueRanges[9],
                    to: this.valueRanges[10],
                    color: '#3e7cd2',
                    name: this.valueRanges[10]
                  }
                ]
              }
            }
          }
        };
        this.chartOptions = {
          ...this.chartOptions,
          xaxis: {
            categories: categories,
            labels: {
              show: false
            }
          },
          yaxis: {
            labels: {
              show: false
            }
          },
          plotOptions: plotOption.plotOptions
        };
        this.chartOptionsLeft = {
          ...this.chartOptionsLeft,
          colors: ['#3e7cd2'],
          yaxis: {
            labels: {
           //   offsetY: -100
            }
          },
          plotOptions: {
            heatmap: {
              radius: 0
            }
          }
        };
        const newCat = categories.map((c, i) => {
          return [c, this.seriesBottom[0].data[i]];
        });
        this.chartOptionsBottom = {
          ...this.chartOptionsBottom,
          colors: ['#3e7cd2'],
          xaxis: {
            categories: newCat,
            labels: {
              show: true
            }
          },
          yaxis: {
            labels: {
              show: false
            }
          }
        };
      }
    },
    daysOfWeekSorter(x, y) {
      return this.daysOfWeek.indexOf(x.name) - this.daysOfWeek.indexOf(y.name);
    },
    async refreshComponent() {
      try {
        const trafficInOut = this.trafficList.trafficList;
        await this.fetchStores();
       await this.fetchPowerHourFidaV2({trafficInOut:trafficInOut,mode: this.mode,daysOfWeek:this.getDaysOfWeekList,storeCodes:this.getStoreCodesList,startDate: this.getStartingPeriod, endDate: this.getEndingPeriod});

      this.populateHeatMap();
    } catch (error) {
      this.$q.notify({
        type: 'negative',
        position: 'top',
        message: error
      });
    } finally {
     //
    }
    }
  }
};
